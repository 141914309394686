<template lang="pug">
include ../../../configs/template
form(@submit.prevent="saveForm")
  div.row.pt-4
    +field-validation('body.number', 'number', '["required"]').col-sm-12.col-md-4
    +field('body.serial', '"serial"').col-sm-12.col-md-4
    +field('body.group', '"group"').col-sm-12.col-md-4
    +select-validation('body.name_nz', 'mappingInstitution', 'nameInstitution', 'labelName', '["required"]').col-sm-12.col-md-6
    +select-validation('body.faculty', 'mappingFaculties', 'way', 'labelName', '["required"]').col-sm-12.col-md-6
    +select-validation('body.education_form', 'mappingEducForm', 'educationForm', 'labelName', '["required"]').col-sm-12
    div.col-sm-12.col-md-6
      +date-picker-validation('body.date_start', 'dataEnrollment', 'dateStartObject', '["required", "maxValue", "minValue"]')
    div.col-sm-12.col-md-6
      +date-picker-validation('body.date_end', 'dateEnd', 'dateEndObject', '["required", "maxValue", "minValue"]')
    +select-validation('body.status_document', 'statusChoose("StudentID")', 'status', 'labelName', '["required"]').col-sm-12
    +checkbox('body.educ_with_dkk','educationWithSQC').mx-2.col-sm-12(color="primary")
    +checkbox('body.passed_educ_exam','passedEducationExam').mx-2.col-sm-12(color="primary")
  div.col-12.form-group.text-left
    FileDropZone.mb-5(ref="mediaContent" v-if="checkAccess('student', 'add_file')")
    div.text-center
      v-btn(color="success" type="submit" :loading="isLoading") {{ $t('save') }}

</template>

<script>
import { SailorStudentForm } from '@/mixins/validationRules'
import { mapActions, mapGetters, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
import { SUCCESS_CODE } from '@/configs/constants'
import { clearBody } from '@/mixins/main'

const initBody = () => {
  return {
    serial: null,
    number: null,
    name_nz: null,
    faculty: null,
    education_form: null,
    group: null,
    date_start: null,
    date_end: null,
    status_document: null,
    educ_with_dkk: false,
    passed_educ_exam: false
  }
}

export default {
  data () {
    return {
      body: initBody(),
      isLoading: false,
      checkAccess
    }
  },
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: { FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue') },
  validations () {
    return SailorStudentForm(this)
  },
  computed: {
    ...mapState({
      lang: (state) => state.main.lang,
      labelName: (state) => (state.main.lang === 'en' ? 'name_eng' : 'name_ukr'),
      mappingInstitution: (state) => state.directory.institution,
      mappingFaculties: (state) => state.directory.faculties,
      mappingEducForm: (state) => state.directory.educationForm
    }),
    ...mapGetters(['statusChoose']),
    dateStartObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    dateEndObject () {
      return this.body.date_end ? new Date(this.body.date_end) : null
    }
  },
  mounted () {
    if (this.$route?.params?.documentID) {
      Object.keys(this.body).forEach((key) => {
        if (typeof this.sailorDocument[key] === 'object') {
          this.body[key] = this.sailorDocument[key]?.id || null
        } else this.body[key] = this.sailorDocument[key]
      })
    }
  },
  methods: {
    ...mapActions(['setCadetCard', 'updateCadetCard']),

    async saveForm () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      let data = {
        ...this.$route.params,
        body: { ...clearBody({ ...this.body }) }
      }
      if (checkAccess('student', 'add_file')) {
        data.media = {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'StudentCard'
        }
      }
      (data.body.sailor = this.$route.params.id)
      const response = await this[`${this.$route?.params?.documentID ? 'update' : 'set'}CadetCard`](data)
      if (SUCCESS_CODE.includes(response.code)) {
        this.clearData()
        this.$v.$reset()
        this.$parent.isViewAddSlot = false
      }
      this.isLoading = false
    },
    clearData () {
      this.body = initBody()
    }
  }
}
</script>
